import React from "react"
import Layout from "../components/layout"
import pipe from "../images/pipe1.png"
import "./bemutato.css"

const FeatureMatrix = () => (
  <Layout>
    <div className="features_container sized-container">
      <table className="features_table" >
        <thead>
        <tr>
          <th className="features"><b>Funkciók</b></th>
          <th className="free" ></th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td className="features">Kiadható feladatok száma</td>
        <td className="free">Korlátlan</td>
        </tr>
        <tr>
        <td className="features">Feladatot végző partnerek száma</td>
        <td className="free">Korlátlan</td>
        </tr>
        <tr>
        <td className="features">E-mail értesítések</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Feladat készültsége százalékosan</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Jegyzet, vázlat a feladatvégzéshez</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Alfeladatok, csekklista, bevásárló lista</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Android mobil, tablet verzió</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Apple iPhone, iPad verzió</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Mobil internet verzió</td>
        <td className="free"><img alt="tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Feladatok átbeszélése chat-szerűen</td>
        <td className="free"><img alt="nem tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">File feltöltés feladathoz</td>
        <td className="free"><img alt="nem tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">100 MB tárhely</td>
        <td className="free"><img alt="nem tartalmazza" src={pipe} /></td>
        </tr>
        <tr>
        <td className="features">Együttműködés a <a href="https://www.justdoo.hu" title="JustDoo feladat és projektkezelő, vállalkozás szervező ">JustDoo projektkezelővel</a></td>
        <td className="free"><img alt="nem tartalmazza" src={pipe} /></td>
        </tr>
        </tbody></table>      
    </div>
  </Layout>
)

export default FeatureMatrix